.dropbtn {
  background-color: #ffffff;
  color: #333333;
  padding: 16px;
  font-size: 16px;
  border: 1px solid #e5edff;
  height: 0.5rem;
  width: 20rem;
  cursor: pointer;
  justify-content: space-evenly;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.dropdown {
  position: relative;
  /*   display: inline-block; */
  z-index: 0;
  width: 20rem;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.title-dropdownlist {
  color: #000000;
  font-weight: 500;
}
