.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Blurred background */
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: #333333;
}
