.box {
  width: 27rem;
  height: 15rem;
  margin: 5px;

  border-radius: 2%;
  margin: 5px;
  background-color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  /*   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
}
.header-box {
  width: 96%;
  border-radius: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: 2%;
}

.grid-layout {
  display: grid;
  gap: 0.1rem;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: dense;
  justify-items: center;
  /*   overflow-y: scroll;
  overflow: scroll; */
}

hr.solid {
  border: 0;
  border-top: 1px solid #e5edff;
  width: 95%;
}

.data-box-body {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
}
.data-info-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2%;
}

.footer-card-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  height: 1rem;
}

.data-little-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.grid-layout-orders {
  display: grid;
  gap: 0.1rem;
  width: 90vw;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: dense;
  justify-items: center;
  background-color: #ffffff;
  height: 90%;
  overflow-y: scroll;
}
.box-orders {
  width: 20rem;
  height: 15rem;
  margin: 5px;

  border-radius: 2%;
  margin: 5px;
  background-color: white;
  /*  cursor: pointer; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  overflow: hidden;
}
.pagination-buttons {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 2rem;
}
.footer-card-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 1rem;
}

.button-close {
  top: 10px;
  right: 20px;
  border: none;
  background-color: #e95a13;
  cursor: pointer;

  border-radius: 50%;
}
.modal {
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  position: fixed;
  z-index: 999;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  left: 0;
  min-height: 100vh;
}

.content-dialog {
  background-color: #ffffff;
  max-height: 80vh;
  min-width: 50vw;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  align-items: center;
  height: 90vh;
}

.content-dialog > * {
  width: 100%; /* Ocupa el 100% del ancho del contenedor */
  max-width: 100%; /* Garantiza que no se desborde horizontalmente */
  box-sizing: border-box; /* Incluye padding y borde en el ancho total */
}
@media (max-width: 1320px) {
  .content-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 90vw; /* Ajusta el ancho como prefieras */
    min-width: 60vw;
    max-height: 70vh; /* Ajusta la altura máxima como prefieras */
    height: 80vh;
  }
}
.transaparentButtons {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.header-dialog {
  background-color: #f7f8fd;

  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  border: 1px solid #e5edff;
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
}

.content-modal {
  margin-top: 5%;
  height: 80%;
}
