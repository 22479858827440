.loginFormLabel {
  font-size: 1rem;
  font-weight: 500;
  color: #828282;
}

@media screen and (max-width: 1100px) {
  .loginFormLabel {
    font-size: 0.75rem;
  }
}
