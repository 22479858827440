/** DateProvider component */
.date-provider {
  margin: 0.5rem 0 0.25rem 0;
}

/** LoadingModal component */
.loading-modal-text {
  color: #fbded0;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #4f4f4f;
}
.modal-text-bottom {
  margin: 4px 0 0 0;
  color: #4f4f4f;
}
.loading-modal {
  margin: 0 0 4px 0;
}
.loading-modal:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 2s infinite;
  animation: ellipsis steps(4, end) 2s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.loading-modal-container {
  display: grid;
  height: 120vh;
  place-content: center;
}

.loading-modal-dialog {
  padding: 0;
  backdrop-filter: blur(5px);
  background-color: white;
  border: none;
  height: 100vh;
  top: 0;
  width: 100vw;
  z-index: 9999;
  position: fixed;
}

@keyframes ellipsis {
  to {
    width: 15px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 15px;
  }
}

/** Message component */
.message {
  margin: 0;
  padding: 0.25rem 0;
}

/** MoneyContainer component */
.money-container {
  background-color: rgba(255, 255, 255, 0.5);
  border: #4f4f4f 1px solid;
  margin-left: 0.5rem;
  padding: 0.375rem 2rem;
  transition-duration: 0.7s;
}
.money-container p {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}
