.success-checkmark {
  /* margin: 1rem 0; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.success-checkmark .check-icon {
  width: var(--width);
  height: var(--height);
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 8px solid #00a447;
}
.success-checkmark .check-icon::before {
  top: 6px;
  left: -4px;
  width: 60px;
  transform-origin: 100% 50%;
  border-radius: 200px 0 0 200px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 60px;
  width: 120px;
  transform-origin: 0 50%;
  border-radius: 0 200px 200px 0;
  animation: rotate-circle 5s ease-in;
}
.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: '';
  height: 200px;
  position: absolute;
  background: #fff;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 12px;
  background-color: #00a447;
  display: block;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 92px;
  left: 28px;
  width: 50px;
  transform: rotate(45deg);
  animation: icon-line-tip 1s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 76px;
  right: 16px;
  width: 94px;
  transform: rotate(-45deg);
  animation: icon-line-long 1s;
}
.success-checkmark .check-icon .icon-circle {
  top: -8px;
  left: -8px;
  z-index: 10;
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 8px solid rgba(0, 164, 71, 0.5);
}
.success-checkmark .check-icon .icon-fix {
  top: 16px;
  width: 10px;
  left: 52px;
  z-index: 1;
  height: 170px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #fff;
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 2px;
    top: 38px;
  }
  54% {
    width: 0;
    left: 2px;
    top: 38px;
  }
  70% {
    width: 100px;
    left: -16px;
    top: 74px;
  }
  84% {
    width: 34px;
    left: 42px;
    top: 96px;
  }
  100% {
    width: 50px;
    left: 28px;
    top: 90px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 92px;
    top: 108px;
  }
  65% {
    width: 0;
    right: 92px;
    top: 108px;
  }
  84% {
    width: 110px;
    right: 0px;
    top: 70px;
  }
  100% {
    width: 94px;
    right: 16px;
    top: 76px;
  }
}
