.out-of-service-container {
  height: 100vh;
  margin: 0.5rem 1rem;
}

.out-of-service-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.out-of-service-paragraph {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align: justify;
}

.out-of-service-image {
  height: 100%;
  object-fit: cover;
  opacity: 0.05;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
