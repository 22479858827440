:root {
  --orange: rgb(233, 90, 19); /** #e95a13 **/
  --orange-light: rgb(242, 141, 90);
  --orange-dark: #B8470F;
  --disabled-color: #999999;
  --white: #ffffff;
  /** **/
  --spinner-color: #e95a13;
  /** **/
  --width: 160px;
  --height: 160px;
  --height-mark: 230px;
  /** **/
  --blue: rgb(0, 73, 144); /** #004990 **/
  --blue-light: rgba(0, 73, 144, 0.8);
  --blue-dark: rgba(0, 73, 144, 0.1);
  --white-blue: #cce5ff;
}

.disp-flex {
  display: flex;
}

.flex-gap {
  gap: 1rem;
}

.dir-row {
  flex-direction: row;
}

.dir-col {
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.start-top {
  justify-content: flex-start;
  align-items: flex-start;
}

.between-center {
  justify-content: space-between;
  align-items: center;
}

.around-center {
  justify-content: space-around;
  align-items: center;
}

.evenly-spaced {
  justify-content: space-evenly;
  align-items: center;
}

.start-center {
  justify-content: flex-start;
  align-items: center;
}

.start-safe {
  justify-content: flex-start;
  align-items: safe;
}

.end-center {
  justify-content: flex-end;
  align-items: center;
}

.disp-grid {
  display: grid;
}

.three-cols {
  grid-template-columns: repeat(3, 1fr);
}

.auto-rows {
  grid-auto-rows: 12rem;
}

.col-gap {
  grid-column-gap: 1.5rem;
}

.margin-vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-horizontal {
  margin-left: 1rem;
  margin-right: 1rem;
}

/** Font colors */
.black-txt {
  color: #333333;
}

.white-txt {
  color: #ffffff;
}

/* * Font weights */
.regular-txt {
  font-family: 'Montserrat';
  font-weight: 500;
}

.medium-txt {
  font-family: 'Montserrat';
  font-weight: 600;
}

.bold-txt {
  font-family: 'Montserrat';
  font-weight: bold;
}

/** Font sizes */
.title-txt {
  font-size: 2.375rem; /* 38px */
}

.subtitle-txt {
  font-size: 1.5rem; /* 24px */
}

.body-txt {
  font-size: 1rem; /* 16px */
}

.detail-txt {
  font-size: 0.875rem; /* 14px */
}

.txt-center {
  text-align: center;
}

/** Border radius */
.radius {
  border-radius: 0.5rem;
}

/** Absolute element */
.right-corner {
  bottom: 0;
  position: absolute;
  right: 0;
}

.margin-corner {
  margin: 0 8.125rem 2.5rem 0;
}

@media screen and (max-width: 900px) {
  .margin-corner {
    margin: 0 4.125rem 2.5rem 0;
  }
}

@media screen and (max-width: 700px) {
  .margin-corner {
    margin: 0 2.125rem 2.125rem 0;
  }
}

@media screen and (max-width: 500px) {
  .margin-corner {
    margin: 0 1.125rem 1.125rem 0;
  }
}
