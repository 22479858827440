.upload {
  padding: 1rem 8rem;
}

@media screen and (max-width: 900px) {
  .upload {
    padding: 1rem 4rem;
  }
}

@media screen and (max-width: 700px) {
  .upload {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 500px) {
  .upload {
    padding: 1rem 1rem;
  }
}
