.loginTitle {
  font-size: 2rem;
  font-weight: 700;
  color: #004990;
}

.loginParagraph {
  font-size: 1rem;
  color: #828282;
}

@media screen and (max-width: 1100px) {
  .loginTitle {
    font-size: 1.75rem;
  }

  .loginParagraph {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 675px) {
  .loginTitle {
    font-size: 1.5rem;
  }
}
