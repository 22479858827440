.update-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e95a13;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  .icon {
    margin-right: 8px;
    font-size: 20px;
  }

  &:hover {
    background-color: #e4743d;
  }
}
