.snackbar-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.snackbar {
  background-color: #FF0000;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
