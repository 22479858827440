.login-form-input {
  padding: 0.6rem 0.75rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #828282;
  color: #000;
}

.login-form-input:focus {
  border: 1px solid #004990;
  outline: 1px solid #004990;
}

@media screen and (max-width: 1100px) {
  .login-form-input {
    font-size: 0.75rem;
  }
}
