* {
  font-family: "Montserrat", sans-serif;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

*:before,
*:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f7f8fd;
  width: 100%;
  height: 100%;
}
.bar-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  background-color: #ffffff;
  opacity: 1;
  position: sticky;
  top: 0;
  z-index: 0;
  justify-items: center;
  height: 5rem;
}

/* .datepickerWrapper > * {
  position: relative;
  z-index: 999;
} */
.btn-filter-bar {
  background-color: white;
  height: 40px;
  width: 100px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-weight: 500;
}
/* .modal-open {
  overflow: hidden;
} */
.btn-filter-bar.selected {
  background-color: #004990;
  height: 40px;
  width: 100px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-weight: 500;
}
.btn-filter-bar:hover {
  border: none;
  background-color: #004990;
  height: 40px;
  width: 100px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
}
.buttons-pagination {
  display: flex;
  /*   position: relative; */
  flex-direction: row;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #e5edff;
  height: 2.5rem;
  border-radius: 10px;
  width: 20%;
  margin-right: 12px;
  /*  left: 76%; */
  /*   margin-top: 5px; */
  justify-content: space-evenly;
}
.buttons-directions-pagination {
  display: inline-block;
  width: 25px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #e96f00;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: #004990;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tooltip:hover {
  background-color: #004990;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #f2f2f2;
  color: #333333;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  top: 8%;
  left: 500%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltiptext h2 {
  margin: 0;
  color: #333333;
  font-size: 24px;
  margin-bottom: 10px;
}

.tooltiptext p {
  margin: 0;
  color: #666666;
  font-size: 16px;
  margin-bottom: 5px;
}

.tooltiptext .label {
  font-weight: bold;
}

.tooltiptext .plate {
  text-transform: uppercase;
}

.tooltiptext .rating {
  color: #ff9900;
  font-size: 18px;
  font-weight: bold;
}

.data-picker {
  width: 10%;
}
/* @media screen and (max-width: 905px) {
  .bar-options {
    display: flex;
    flex-direction: column;
  }
  .buttons-pagination {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: white;
    align-items: center;
    border: 1px solid #e5edff;
    height: 2.5rem;
    border-radius: 10px;
    width: 99%;
    left: 0;
    margin-top: 5px;
    justify-content: space-evenly;
  }
} */
