tr {
  cursor: pointer;
}
tr:hover {
  cursor: pointer;
  background-color: #004990;
  color: white;
}

.t-head-travels-history {
  background-color: #004990;
  color: white;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  display: inline-block;
  width: 25px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #004990;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.pagination button.active {
  background-color: #e96f00;
}

@media only screen and (max-width: 600px) {
  table {
    font-size: 12px;
  }
}
