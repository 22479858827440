.spinner {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 30px solid rgba(233, 111, 0, 0.2);
  border-top-color: #e96f00;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
