.loginFormBtn {
  background: #ea5b13;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  min-width: 200px;
  cursor: pointer;
  margin: 2.5rem auto 1rem;
}
.loginFormBtn:hover {
  background: rgba(234, 91, 19, 0.9);
}
.loginFormBtn:active:not(:disabled) {
  background: #db5512;
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}
.loginFormBtn:disabled {
  background: #dedede;
  color: #999999;
}

@media screen and (max-width: 675px) {
  .loginFormBtn {
    min-width: 100px;
    font-size: 0.75rem;
    padding: 0.75rem 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .loginFormBtn {
    width: auto;
  }
}
