.orange-button {
  background: var(--orange);
  border: 1px solid var(--orange);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orange-button:hover {
  background: var(--orange-light);
}
.orange-button:active:not(:disabled) {
  background: var(--orange-dark);
  box-shadow: 0 2px #333;
  transform: translateY(2px);
}
.orange-button:disabled {
  background: var(--disabled-bg);
  border: 1px solid var(--disabled-color);
  color: var(--disabled-color);
  cursor: not-allowed;
}

/** MainButton component */
.main-btn {
  min-height: 2rem;
  min-width: 8rem;
  padding: 0.5rem 2.5rem;
}

/** SearchButton component */
.search-btn {
  border-radius: 2rem;
  margin: 0 0.25rem;
  min-width: 2rem;
  min-height: 2rem;
  padding: 0.5rem;
}

/** SecondaryButton component */
.secondary-btn {
  background: #ffffff;
  border: 1px solid var(--orange);
  color: var(--orange);
  cursor: pointer;
  margin-right: 4px;
  min-height: 2rem;
  min-width: 8rem;
  overflow: hidden;
  padding: 0.4rem 2.5rem;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
.secondary-btn:hover {
  background: #dedede;
}
.secondary-btn:active:not(:disabled) {
  box-shadow: 0 2px #666;
  transform: translateY(2px);
}
.secondary-btn:disabled {
  background: var(--disabled-bg);
  border: 1px solid var(--disabled-color);
  color: var(--disabled-color);
}

/** StepsButton component */
.steps-btn {
  width: fit-content;
  cursor: pointer;
  user-select: none;
  border: none;
  background-color: #ffffff;
  padding: 0.5em 0.5rem;
  margin-top: 0.25em;
  margin-bottom: 0.25rem;
}
.steps-btn:hover {
  background-color: rgba(24, 144, 255, 0.1);
}
.steps-btn:active {
  background-color: rgba(24, 144, 255, 0.2);
}

.steps-circle {
  background-color: #004990;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.steps-circle p {
  margin-top: 0;
  margin-bottom: 0;
}

.steps-txt {
  margin-left: 0.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.steps-txt p {
  margin-top: 0;
  margin-bottom: 0;
  color: #004990;
}

/** Option Button component */
.option-btn {
  background-color: var(--white);
  border-radius: 4px;
  border: 2px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
  /* ? height: 10rem; */
  max-width: 24rem;
  /* ? min-width: 24rem; */
  padding: 1rem;
  user-select: none;
  /* ? justify-self: center; */
}
.option-btn:hover {
  background-color: var(--white-blue);
}
.option-btn:active {
  background-color: var(--white-blue);
  box-shadow: 0 2px #333;
  transform: translateY(2px);
}

.option-selected {
  background: linear-gradient(90deg, hsla(210, 100%, 28%, 1) 60%, hsla(210, 100%, 18%, 1) 100%);
  border-radius: 4px;
  border: 2px inherit var(--white);
  color: var(--white);
  cursor: pointer;
  /* ? height: 10rem; */
  max-width: 24rem;
  /* ? min-width: 24rem; */
  padding: 1rem;
  user-select: none;
  /* ? justify-self: center; */
}
.option-selected:hover {
  background: linear-gradient(90deg, hsla(210, 100%, 33%, 1) 61%, hsla(210, 100%, 43%, 1) 100%);
}
.option-selected:active {
  background: linear-gradient(90deg, hsla(210, 100%, 33%, 1) 61%, hsla(210, 100%, 43%, 1) 100%);
  box-shadow: 0 2px #333;
  transform: translateY(2px);
}
