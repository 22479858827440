.loginImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 4.5rem; */
}

.loginNikuHere {
  width: 420px;
  height: 100%;
  object-fit: contain;
  z-index: 10;
}

.loginNikuLogo {
  width: 264px;
  object-fit: cover;
  z-index: 12;
}

@media screen and (max-width: 1200px) {
  .loginNikuHere {
    width: 400px;
  }

  .loginNikuLogo {
    width: 248px;
  }
}

@media screen and (max-width: 1100px) {
  .loginNikuHere {
    width: 365px;
  }

  .loginNikuLogo {
    width: 240px;
  }
}

@media screen and (max-width: 1000px) {
  .loginNikuHere {
    width: 380px;
  }

  .loginNikuLogo {
    width: 200px;
  }
}

@media screen and (max-width: 900px) {
  .loginNikuHere {
    width: 0px;
  }

  .loginNikuLogo {
    width: 0px;
  }
}
