.floating-button-container-excel {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 0;
}

.floating-button-excel {
  width: 60px;
  height: 65px;
  border-radius: 50%;
  background-color: green;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  animation: floating 1s ease-in-out infinite;
}

.div-spinner-status-excel {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #004990;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  /*   animation: floating 3s ease-in-out infinite; */
}
.floating-button-excel::after {
  content: "Exportar selección a excel de los conductores encontrados";
  position: absolute;
  bottom: 100%;
  left: -100px;
  transform: translateX(-50%);
  width: 200px;
  padding: 8px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.floating-button-excel:hover::after {
  opacity: 1;
  visibility: visible;
}

.floating-button-excel::before {
  content: "";
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #333 transparent transparent transparent;
}

.floating-text {
  position: relative;
  display: inline-block;
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.floating-text::after {
  position: absolute;
  top: 0;
  right: -30px;
  width: 30px;
  height: 100%;
  background-color: #f00; /* Cambia el color del fondo según tu preferencia */
}
