.login {
  width: 100%;
  height: 100vh;
}

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-image: url('../../assets/niku-bg.jpeg');
  background-repeat: repeat repeat;
  background-size: contain;
}

.login-form {
  width: 32vw;
  margin-left: -16rem;
  margin-top: -2rem;
  padding: 2rem 4.125rem 2rem 18rem;
  border-radius: 6rem 3rem 3rem 9rem;
  background-color: #fff;
}

.login-form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .login-form {
    width: 40vw;
    padding: 2rem 4rem 2rem 18rem;
    border-radius: 2.5rem 2.5rem 2.5rem 9rem;
  }
}

@media screen and (max-width: 1100px) {
  .login-form {
    width: 32vw;
    border-radius: 2.5rem 2.5rem 2.5rem 9rem;
    -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 10% 100%);
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 10% 100%);
  }
}

@media screen and (max-width: 900px) {
  .login-form {
    width: 48vw;
    margin-left: 0rem;
    padding: 2rem 4rem 2rem 4rem;
    border-radius: 48px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@media screen and (max-width: 600px) {
  .login-form {
    width: 64vw;
    padding: 4rem 2rem;
  }
}
