.topBarLay {
  width: 100%;
  height: auto;
  top: 0;
  box-shadow: 0px -5px 50px rgba(51, 51, 51, 0.4);
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 8rem;
}

.topBarBtn {
  align-items: center;
  background: #f2f2f2;
  border-radius: 1rem;
  border: 1px solid var(--orange-light);
  color: var(--orange);
  cursor: pointer;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem 1.5rem;
}
.topBarBtn:hover {
  border: 1px solid var(--orange);
  color: var(--orange-dark);
}
.topBarBtn:active {
  background-color: #e95a13;
  box-shadow: 0 2px #666;
  transform: translateY(1px);
}

.topBarBtnLbl {
  margin: 0 0 0 0.25rem;
}

@media screen and (max-width: 900px) {
  .topBar {
    padding: 0.5rem 4rem;
  }
}

@media screen and (max-width: 700px) {
  .topBar {
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 500px) {
  .topBar {
    padding: 0.5rem 1rem;
  }
}
