/** AbortButton component */
.abort-button {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  border: 1px solid var(--orange);
  color: var(--orange);
  cursor: pointer;
  min-height: 2.75rem;
  min-width: 10rem;
  padding: 0.5rem 2rem;
  user-select: none;
}
.abort-button:hover {
  background: rgba(255, 255, 255, 0.8);
  color: var(--orange-light);
  border-color: var(--orange-light);
}
.abort-button:active:not(:disabled) {
  box-shadow: 0 2px #333;
  transform: translateY(2px);
}
.abort-button:disabled {
  background: var(--disabled-bg);
  border: 1px solid var(--disabled-color);
  color: var(--disabled-color);
  cursor: not-allowed;
}

/** BulletPoint component */
.bullet-point {
  margin: 0;
  padding: 0.25rem 2.25rem;
}

/** FirstStep & SecondStep component */
.step {
  margin: 0 auto;
  padding: 0.25rem 2.25rem;
}

.input-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/** UploadButton component */
.upload-button {
  background: var(--orange);
  border-radius: 8px;
  border: 1px solid var(--orange);
  cursor: pointer;
  padding: 0.5rem 2rem;
  user-select: none;
  min-width: 10rem;
  min-height: 2.75rem;
}
.upload-button:hover {
  background: var(--orange-light);
  border-color: var(--orange-light);
}
.upload-button:active:not(:disabled) {
  background: var(--orange-light);
  box-shadow: 0 2px #333;
  transform: translateY(2px);
}
.upload-button:disabled {
  background: #dedede;
  border: 1px solid #999999;
  color: #999999;
  cursor: not-allowed;
}

/** General */
.spacing {
  height: 1rem;
}

.text-center {
  text-align: center;
}

.step-container {
  margin: 8px 0px 2px 0px;
  display: grid;
  place-items: center;
}

.step-circle {
  background: var(--blue);
  width: 3rem;
  height: 3rem;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
}
