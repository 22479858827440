.dispersion-delivery {
  /*   padding: 0 1.5rem 0.5em 1.5rem; */
}

.space {
  height: 0.5rem;
}

.delivery-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
