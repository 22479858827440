.mainlayout {
  width: 100%;
}

.mainlayout-container {
  width: calc(100% - 15rem);
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 15rem;
}

.nav-bar {
  position: fixed;
  background: #fff;
  height: 100%;
  width: 15rem;

  z-index: 0;
  overflow: auto;
}

.sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  overflow: auto;
}

.sidebar-item-top {
  padding-left: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sidebar-item-bottom {
  margin-top: auto;
  margin-bottom: 2rem;
}

.sidebar-img {
  width: 180px;
  height: 100%;
  object-fit: contain;
}

.sidebar-item-selected {
  box-sizing: border-box;
  width: 90%;
  border-radius: 0px 16px 16px 0px;
  font-size: 1.125rem;
  margin-top: 0.2rem;
  padding: 1rem 1rem 1rem 2rem;
  cursor: pointer;
  border: none;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: #fff;
  background: #004990;
}
.sidebar-item-selected:focus,
.sidebar-item-selected:visited,
.sidebar-item-selected:link {
  text-decoration: none;
  color: #fff;
}

.sidebar-item {
  box-sizing: border-box;
  width: 90%;
  cursor: pointer;
  border-radius: 0px 16px 16px 0px;
  font-size: 1.125rem;
  margin-top: 0.2rem;
  padding: 1rem 1rem 1rem 2rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: #4f4f4f;
  border: none;
}
.sidebar-item:focus,
.sidebar-item:visited,
.sidebar-item:link {
  text-decoration: none;
  color: #4f4f4f;
}
.sidebar-item:hover {
  background: #003366;
  color: #fff;
  text-decoration: none;
}
.sidebar-item:active {
  background-color: #003366;
  box-shadow: 0 2px #666;
  transform: translateY(1px);
}

.sidebar-btn-logout {
  width: calc(100% - 3rem);
  padding: 0.7rem 1rem 0.7rem 2rem;
  border-radius: 0px 16px 16px 0px;
  font-weight: 600;
  font-size: 1.125rem;
  border: none;
  background: #f2f2f2;
  color: #e95a13;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}
.sidebar-btn-logout:hover {
  background: #e95a13;
  color: #fff;
  text-decoration: none;
}
.sidebar-btn-logout:active {
  background-color: #e95a13;
  box-shadow: 0 2px #666;
  transform: translateY(1px);
}

.sidebar-btn-lbl {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.25rem;
}

.accordion-btn {
  width: auto;
  padding: 1rem 1.5rem;
  margin-top: 0.4rem;
  border-radius: 0px 16px 16px 0px;
  font-weight: 600;
  font-size: 1.125rem;
  border: none;
  background: #f2f2f2;
  color: #e95a13;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.accordion-btn-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* ****************************************************************************** */

/* @media screen and (max-width: 996px) {
  .sidebar {
    display: none;
  }
}
 */
@media screen and (max-height: 996px) {
  .sidebar-item-top {
    padding-left: 2rem;
    margin-top: 5.5rem;
    margin-bottom: 1rem;
  }
  .sidebar-img {
    height: 100px;
    aspect-ratio: 512/265;
    object-fit: contain;
  }

  .sidebar-item-selected,
  .sidebar-item {
    font-size: 1rem;
    margin: 0.25rem 0;
    padding: 1rem 2rem;
  }

  .sidebar-item-bottom {
    margin-top: 5rem;
  }

  .sidebar-btn-logout {
    padding: 0.5rem 1rem 0.5rem 2rem;
    font-size: 1rem;
  }
}

@media screen and (max-height: 550px) {
  .sidebar-item-top {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .sidebar-item-bottom {
    margin-top: 2rem;
  }
}

@media screen and (max-height: 450px) {
  .sidebar-item-top {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .sidebar-item-bottom {
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 996px) {
  .mainlayout-container {
    width: 100%;
    margin-left: 0;
  }

  .nav-bar {
    width: 0;
    height: auto;
    bottom: 0;
    box-shadow: 0px -5px 50px rgba(51, 51, 51, 0.4);
  }
}
