/** BodyText component */
.body-text-common {
  margin: 0;
  padding: 0.25rem 0;
}

/** Title component */
.title-common {
  margin: 0;
  padding: 0.5rem 0;
}
