.uploadForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0.25rem;
}

.inputDisabled {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.inputDisabled + label {
  color: var(--disabled-color);
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.5rem 2rem;
  min-width: 18.25rem;
  text-align: center;
}

.inputDisabled + label * {
  pointer-events: none;
}

.inputFile {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.inputFile + label {
  color: var(--blue);
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.5rem;
  min-width: 9.5rem;
}

.inputFile:focus + label,
.inputFile + label:hover {
  color: var(--blue-light);
  background-color: var(--blue-dark);
}

.inputFile + label * {
  pointer-events: none;
}
