.sa {
  /* width: 165px;
  height: 165px; */
  /* background-color: #fff; */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sa-error {
  border-radius: 50%;
  border: 8px solid #f27474;
  box-sizing: content-box;
  height: 160px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 160px;
  animation: animateErrorIcon 1s;
}
.sa-error:after,
.sa-error:before {
  background: #fff;
  content: '';
  height: 240px;
  position: absolute;
  transform: rotate(45deg);
  width: 120px;
}
.sa-error:before {
  border-radius: 80px 0 0 80px;
  width: 56px;
  height: 160px;
  top: -34px;
  left: 11px;
  transform-origin: 120px 120px;
  transform: rotate(-45deg);
}
.sa-error:after {
  border-radius: 0 240px 240px 0;
  left: 55px;
  top: -22px;
  transform-origin: 0 120px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 5s ease-in;
}
.sa-error-x {
  display: block;
  position: relative;
  z-index: 2;
}
.sa-error-placeholder {
  border-radius: 50%;
  border: 8px solid rgba(200, 0, 0, 0.2);
  box-sizing: content-box;
  height: 160px;
  left: -8px;
  position: absolute;
  top: -8px;
  width: 160px;
  z-index: 2;
}
.sa-error-fix {
  background-color: #fff;
  height: 180px;
  left: 56px;
  position: absolute;
  top: 16px;
  transform: rotate(-45deg);
  width: 10px;
  z-index: 1;
}
.sa-error-left,
.sa-error-right {
  border-radius: 4px;
  display: block;
  height: 10px;
  position: absolute;
  z-index: 2;
  background-color: #f27474;
  top: 74px;
  width: 94px;
}
.sa-error-left {
  left: 34px;
  transform: rotate(45deg);
  animation: animateXLeft 1s;
}
.sa-error-right {
  right: 32px;
  transform: rotate(-45deg);
  animation: animateXRight 1s;
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%,
  65% {
    left: 164px;
    top: 190px;
    width: 0;
  }
  84% {
    left: 28px;
    top: 66px;
    width: 94px;
  }
  100% {
    left: 34px;
    top: 74px;
    width: 94px;
  }
}
/* ? Language: css */
@keyframes animateXRight {
  0%,
  65% {
    right: 164px;
    top: 190px;
    width: 0;
  }
  84% {
    right: 28px;
    top: 66px;
    width: 94px;
  }
  100% {
    right: 32px;
    top: 74px;
    width: 94px;
  }
}
