.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid rgb(229, 237, 255);
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  /*   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 250px;
  max-width: 100%;
  /*  transition: background-color 0.3s ease; */
}

/* select:hover {
  background-color: #df9e62;
}
 */
select:focus {
  outline: none;
  background-color: #fff;
}

option {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
}

option:hover {
  background-color: black;
  color: white;
}
