.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
}

.grid-layout-shops {
  display: grid;
  gap: 0.1rem;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: dense;
  justify-items: center;
  overflow-y: scroll;
}

.box-shop {
  width: 27rem;
  height: 15rem;
  margin: 5px;
  border-radius: 2%;
  margin: 5px;
  background-color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  /*   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
}
.header-box {
  width: 96%;
  border-radius: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: 2%;
}
.data-box-body-shopcard {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  padding: 10px;
}

.img-shopCard {
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}
.data-info-box-shopcard {
  width: 100%;
  margin-left: 2%;
  overflow: hidden;
  /*   overflow: hidden;
  overflow-y: scroll; */
}
.txt-title-informationShop {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  overflow: hidden;
  height: auto;
  max-width: 100%;
}
.txt-informationShop {
  line-height: 0px;
  color: #333333;
  font-size: 15px;
}
.txt-informationShop-footer {
  line-height: 0px;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
}
.footer-card-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grand-box {
  background-color: white;
  width: 27rem;
  margin: 5px;
  height: auto;
  border-radius: 2%;
  overflow: hidden;
  scroll-behavior: smooth;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.img-contain {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}
.img-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  resize: stretch;
}
.header-grand-box {
  width: auto;
  border-radius: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body-grandbox {
  width: auto;
  height: 400px;
  align-items: center;
  padding: 2%;
}
.box-content {
  padding: 2%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
  height: 18rem;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.box-data {
  height: auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.title-grand-box {
  color: #333333;
  font-size: 1rem;
  font-family: "Montserrat";
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}
.subtitle {
  font-weight: 500;
}

.title-address {
  text-align: right;
}

.content-btn-collects {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-collect {
  background-color: #3fa012;
  width: 8rem;
  height: 3rem;
  border-radius: 12px;
  color: aliceblue;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.btn-collect:hover {
  transition: background-color 0.5s;
  background-color: #9cb491;
}

.btn-dispersion {
  background-color: #ea5b13;
  width: 8rem;
  height: 3rem;
  color: aliceblue;
  font-size: 1rem;
  border-radius: 12px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.btn-dispersion:hover {
  transition: background-color 0.5s;
  background-color: #eca37f;
}

hr.solid {
  border: 0;
  border-top: 1px solid #e5edff;
  width: 95%;
}

/* Estilo date range */
.daterange-picker {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 3rem;
  font-size: 14px;
  width: 20rem;
  padding: 10px;
}
/* Estilo botones paginación */

.btn-dateicker {
  background-color: white;
  height: 2.5rem;
  width: 20rem;
  border: 0px;
  border-radius: 10px;
  border: 1px solid #e5edff;
  cursor: pointer;
  font-size: 15px;
}

.data-little-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.button-action-cardShop {
  width: 80px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border: 1px solid #e5edff;
  justify-content: space-evenly;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.button-upArrow {
  background-color: #e5edff;
  border: none;
  width: 30px;
  height: 30px;
  margin-left: 2%;
  border-radius: 5px;
  cursor: pointer;
}
.button-DownArrow {
  background-color: #ffffff;
  border: 1px solid #e5edff;
  width: 30px;
  height: 30px;
  margin-left: 2%;
  border-radius: 5px;
  cursor: pointer;
}
.txt-id {
  color: #333333;
  font-size: 17px;
  font-weight: 500;
}
.button-Movements {
  background-color: #004990;
  width: 20vh;
  height: 4vh;
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 7px;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  display: flex;
  cursor: pointer;
}
.button-Movements-disabled {
  background-color: gray;
  width: 20vh;
  height: 4vh;
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #333333;
  font-size: 15px;
  padding: 7px;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  display: flex;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    height: 0px;
    width: 0vw;
  }
  100% {
    opacity: 1;
    height: "500px";
  }
}
