.date-label {
  padding: 0.438rem 1rem;
}
.date-label p {
  margin-top: 0;
  margin-bottom: 0;
}

.main-container {
  height: 340px;
  width: '100%';
}

.row-container {
  width: 100%;
  margin: 0 auto;
  padding: 0.25rem 0;
}

.top-container {
  justify-content: space-evenly;
  align-items: center;
  padding: 0.25rem 0;
}
